import firebase from 'firebase/app';
//import 'firebase/app';

import "firebase/analytics";
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyATUcPyfDBsSG44QY7mlCQV3L-R2gjX5YU",
    authDomain: "fu-main.firebaseapp.com",
    databaseURL: "https://fu-main.firebaseio.com",
    projectId: "fu-main",
    storageBucket: "fu-main.appspot.com",
    messagingSenderId: "485129829664",
    appId: "1:485129829664:web:5a7f39cd1919b5e9c62b45",
    measurementId: "G-4MWY0SJCV0"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();