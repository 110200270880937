<template>
  <section id="facilities" class="facilities">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="section-heading">
            <h1>Never miss a day</h1>
            <p class="text-muted">What can you accomplish with 24 hour Access?</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="device-container">
            <div class="device-mockup simple_window portrait">
              <div class="device">
                <div class="screen">
                  <img src="../img/deco/homePageBiker.jpg" class="img-responsive" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="container-fluid">
            <div class="row row-cols-2">
              <div class="col" v-for="stat in stats" :key="stat.title">
                <div class="feature-item">
                  <i class="fa text-primary" :class="stat.icon"></i>
                  <h3>{{ stat.title }}</h3>
                  <p class="text-muted">{{ stat.body }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import Grammar from "@/components/grammar";
export default {
  name: "facilities",
  components: {/*Grammar*/},
  data(){
    return{
      stats:[
        {
          icon:"fa-heart",
          title: "Cardio",
          body:"With 22 pieces of cardio equipment, gym members have options ranging from elliptical trainers, treadmills, recumbent and upright bicycles, and stair climbers, 13 of which have cable television.",
        },
        {
          icon:"fa-link",
          title: "Lifting Stations",
          body:"We offer a full range of selectorized, plate loaded and cable resistance machines, along with 2 flat benches, an incline and decline bench, and a squat rack.",
        },
        {
          icon:"fa-hand-rock-o",
          title: "Free Weights",
          body:"Urethane coated dumb bells, 5-100lbs by 5lbs increments. Fixed Weight barbells, 20-110 by 10lbs increments.",
        },
        {
          icon:"fa-shower",
          title: "Changing Room & Shower",
          body:"We have 4 unisex bathrooms, all with showers, 2 of which are handicap accessible.",
        },
      ],
    }
  }
}
</script>

<style scoped>
.facilities{
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background: url("../img/bg/bg-pattern5.png"), #3d57a5;
  /* fallback for old browsers */
  background: url("../img/bg/bg-pattern5.png"), -webkit-linear-gradient(to left, #3d57a5, #e08225);
  /* Chrome 10-25, Safari 5.1-6 */
  background: url("../img/bg/bg-pattern5.png"), linear-gradient(to left, #3d57a5, #e08225);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  font-family: "Catamaran","Helvetica","Arial","sans-serif";
  -webkit-font-smoothing: antialiased;
  padding-top: 51px;
  padding: 51px 1vh 3vw 5vw;
}
.section-heading{
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.img-responsive{
  width: 100%;
  background: url("../img/deco/imgBorder.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  padding: 0.5vw;
  border-radius: 1vh;
}

.feature-item{
  min-height: 25vh;
  margin-top: 5vh;
  text-align: center;
}

i{
  font-size: 80px;
  background: -webkit-gradient(linear, left top, right top, from(#e08225), to(#3d57a5));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>