<template>
  <section id="trainers" class="trainers bg-primary text-center">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <br />
          <h1>Meet the Trainers and Staff</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <TrainerList :trainers="trainersData" :userBind="loggedIn"/>
        </div>
        <div class="col-md-4">
          <TrainerCarousel :trainers="trainersData" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TrainerList from "@/components/trainerList";
import TrainerCarousel from "@/components/TrainerCarousel";

import { db } from '../firebaseDB';

export default {
  name: "trainers",
  components: {
    TrainerCarousel,
    TrainerList
  },
  props:{
    loggedIn: Object
  },
  created(){
    db.collection('trainersData').onSnapshot((snapshotChange) => {
      this.trainersData = [];
      snapshotChange.forEach((item) => {

        this.trainersData.push({
          id: item.data().id,
          name: item.data().name,
          image: item.data().image,
          intro: item.data().intro,
          owner: item.data().owner,
          services: item.data().services,
          uid: item.id,
        })
      });
    })
  },
  // updated() {
  //   console.log('Trainer', this.user)
  // },
  data() {
    return{
      trainersData: [
        {
          owner: 'Loading',
          id: "loading",
          name: "Loading",
          image: "loading.gif",
          intro: "Loading!",
          services: "Loading!",
          uid: 'Loading'
        },
      ]
    }
  }
}
</script>

<style scoped>
.trainers{
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background: url("../img/bg/bg-pattern4.png"), #3d57a5;
/* fallback for old browsers */
  background: url("../img/bg/bg-pattern4.png"), -webkit-linear-gradient(to left, #3d57a5, #e08225);
/* Chrome 10-25, Safari 5.1-6 */
  background: url("../img/bg/bg-pattern4.png"), linear-gradient(to left, #3d57a5, #e08225);
  background-attachment: fixed;
/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  font-family: "Catamaran","Helvetica","Arial","sans-serif";
  -webkit-font-smoothing: antialiased;
  padding-top: 51px;
  padding: 51px 1vh 3vw 5vw;
}
</style>