<template>
  <div class="col-md-8 offset-md-1">
    <div class="card" >
      <div class="card-body">
        <h5 class="card-title">{{ CustomerComment.title }}</h5>
        <p class="card-text">{{ CustomerComment.body }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerComment",
  props:{
    CustomerComment: Object
  }
}
</script>

<style scoped>
.card{
  margin: 1.5vh;
  padding: 0;
  font-family: "Muli","Helvetica","Arial","sans-serif";
  -webkit-font-smoothing: antialiased;
}
.card-body{
  margin: 0vh;
  padding: 0vh;
}
.card-title{
  background: #3d57a5;
  background: linear-gradient(
      270deg
      ,#3d57a5,#e08225);
  padding: 1vh 0.5vw;
  color: white;
  text-align: start;
  line-height: 20px;
  font-size: 14px;
}
.card-text{
  padding: 1vh 0.5vw;
}
</style>