<template>
  <menuBar :logged-in="user.connected" @loginClick="loginClickAction" />
  <landingPage />
  <trainers :logged-in="user" />
  <facilities />
  <signup />
  <contact />
</template>

<script>
import menuBar from './components/menuBar'
import landingPage from './pages/landingPage'
import trainers from './pages/trainers'
import facilities from './pages/facilities'
import signup from './pages/signup'
import contact from './pages/contact'
import {db} from './firebaseDB'
import firebase from 'firebase/app'

export default {
  name: 'App',
  components: {
    menuBar,
    landingPage,
    trainers,
    facilities,
    signup,
    contact
  },
  data(){
    return{
      user: {
        uid: '',
        role: '',
        connected: false
      },
    }
  },
  mounted: function () {
    console.log("You read the log?")
    firebase.auth().onAuthStateChanged((result) => {
      if (result) {
        console.log('UID: ',result.uid)
        this.user.uid = result.uid
        this.user.connected = true
        this.user.role = 'user'

        var docRef = db.collection("users").doc(result.uid);
        // console.log('1')
        docRef.get().then((doc) => {
          // console.log('2')
          if (doc.exists) {
            console.log('Welcome ', doc.data().role)
            this.user.role = doc.data().role
          } else {
            console.log('Welcome not admin')
            // db.collection('users').doc(result.user.uid).set({
            //   // id: id,
            //   role: 'user',
            //   email: result.user.email
            // },{merge: false})
            // self.user.name = 'New User'
          }
        }).catch((e) => {
          console.log('Catch user start', e)
        })
      } else {
        this.user.connected = false
        this.user.uid = ''
        this.user.role = ''
      }
    });
  },

  methods:{
    loginClickAction(){
      console.log(this.user)
      if(this.user.connected == true){
        this.user.connected = false
        this.user.uid = ''
        this.user.role = ''
        firebase.auth().signOut()
      }
      else if(this.user.connected == false){
        console.log("Let user sign in")
        var provider = new firebase.auth.GoogleAuthProvider()
        firebase.auth().signInWithPopup(provider).then((result) => {

          // This gives you a Google Access Token.
          //var token = result.credential.accessToken;
          // The signed-in user info.
          console.log('UID: ',result.user.uid)
          this.user.uid = result.user.uid
          this.user.connected = true
          this.user.role = 'user'

          var docRef = db.collection("users").doc(result.user.uid);
          // console.log('1')
          docRef.get().then((doc) => {
            // console.log('2')
            if (doc.exists) {
              console.log('Welcome ', doc.data().role)
              this.user.role = doc.data().role
            } else {
              console.log('Welcome not admin')
              // db.collection('users').doc(result.user.uid).set({
              //   // id: id,
              //   role: 'user',
              //   email: result.user.email
              // },{merge: false})
              // self.user.name = 'New User'
            }
          }).catch((e) => {
            console.log('Catch user start', e)
          })
        }).catch((error) => {
          //You could display the error by added a value in the catch statement
          console.log("Sign In Error: ", error)
          firebase.auth().signOut()
        });

      }
      else{
        //not captured event type
        console.log('Child Trigger Event:')
      }
    }
  }
}
</script>

<style>

body, html {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
</style>
