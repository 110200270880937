<template>
  <div class="container-fluid">
    <div class="row">
      <template v-for="train in trainers" :key="train.id">
        <div class="col-lg-3 col-md-4 col-xs-6 thumb">
          <img :alt="train.name" :src="getPic(train.image)" :id="train.id" class="img-responsive img-rounded staff trainer" @click="trainerClick(train)" data-bs-toggle="modal" data-bs-target="#trainerMsg"/>
        </div>
      </template>
      <!-- This breaks everyhing -->
      <div class="modal fade" id="trainerMsg" tabindex="-1" aria-labelledby="trainerMsg" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ trainerShow.name }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="!trainerShow.admin" class="modal-body">
              <div v-html="trainerShow.intro"></div>
              <br />
              <div v-if="trainerShow.services !== ''">
                Services
                <div v-html="trainerShow.services"></div>
              </div>
            </div>
            <div v-if="trainerShow.admin" class="model-body">
              <Editor
                      api-key="40dlzaqnmh6q817cd2f70iy01b40ivj1njs3bh6e5sa40wd3"
                      v-model="trainerShow.intro"
                      :init="{
                     height: 250,
                     menubar: false,
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     toolbar:
                       'undo redo | formatselect | bold italic textcolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help'
                   }"
              />
              <br />
              <Editor
                      api-key="40dlzaqnmh6q817cd2f70iy01b40ivj1njs3bh6e5sa40wd3"
                      v-model="trainerShow.services"
                      :init="{
                     height: 250,
                     menubar: false,
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     toolbar:
                       'undo redo | formatselect | bold italic textcolor backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help'
                   }"
              />
            </div>
            <div v-if="trainerShow.admin" class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="trainerSave">Save changes</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Here -->
    </div>
    <div class="row customerBox">
      <CustomerComment v-for="cc in CComment" :key="cc.title" :CustomerComment="cc" />
    </div>
  </div>
</template>

<script>
import CustomerComment from "@/components/CustomerComment";
import { db } from '../firebaseDB';
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "trainerList",
  components: {CustomerComment, Editor},
  props:{
    trainers: Array,
    userBind: Object
  },

  created(){
    db.collection('customerTest').onSnapshot((snapshotChange) => {
      this.CComment = [];
      snapshotChange.forEach((item) => {
        this.CComment.push({
          title: item.data().author,
          body: item.data().quote,
        })
      });
    })
  },
  // updated() {
  //   console.log('TrainerList', this.userBind.role)
  // },
  data(){
    return{
      trainerShow: {
        show: false,
        admin: false,
        owner: 'Loading',
        name: "Loading",
        intro: "Loading...",
        services: "Still Loading!!!",
        id: 'Loading',
        uid: ''
      },
      CComment: [
        {
          title:"Loading",
          body:"Loading new comments!"
        },
      ],
    }
  },
  methods: {
    getPic(name){
      var images = require.context('../img/trainer/', false)
      return images('./' + name)
    },
    trainerClick(train){
      this.trainerShow.admin = train.owner !== '' && (this.userBind.uid === train.owner)
      this.trainerShow.show = true
      this.trainerShow.owner = train.owner
      this.trainerShow.name = train.name
      this.trainerShow.intro = train.intro
      this.trainerShow.services = train.services
      this.trainerShow.id = train.id
      this.trainerShow.uid = train.uid
    },
    trainerSave(){
      // console.log('ts', this.trainerShow.intro, this.trainerShow.services)
      console.log('TrainerSave Click')
      // console.log('id',this.trainerShow.uid)
      // console.log('intro',this.trainerShow.intro)
      // console.log('services',this.trainerShow.services)
      db.collection('trainersData').doc(this.trainerShow.uid).set({
        // id: id,
        intro:this.trainerShow.intro,
        services:this.trainerShow.services
      },{merge: true})
    }
  }
}
</script>

<style scoped>
.container-fluid{
  -webkit-font-smoothing: antialiased;
}

img {
  margin-top: 0;
  max-width: 158px;
}

.customerBox{
  padding: 1vh;
}

.trainer:hover {opacity: 0.7;}
</style>