<template>
  <section id="contact" class="contact">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2>We <i class="fa fa-heart"></i> new friends!</h2>
          <a href="https://www.facebook.com/FitnessUnbound/"><i class="fa fa-facebook"></i></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "contact"
}
</script>

<style scoped>
.contact{
  position: relative;
  width: 100%;
  min-height: 25vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #3d57a5;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  font-family: "Catamaran","Helvetica","Arial","sans-serif";
  -webkit-font-smoothing: antialiased;
  padding-top: 10vh;
  padding-bottom: 10vh;
  color: white;
  text-align: center;
}

.fa-heart{
  color: red;
}

a{
  background-color: #486ab4;
  display: block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 40px;
  border-radius: 100%;
  text-align: center;
  margin: auto;
}
a:hover {
  background-color: #486ab4;
}
.fa-facebook{
  font-size: 40px;
  color: white;
}
</style>