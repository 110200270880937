<template>
  <div id="trainerCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#trainerCarousel" :data-bs-slide-to="index" class="active" :aria-label="'Slide ' + (index + 1)"
              v-for="(train, index) in trainers" :key="train.id" :class="{ 'active': index === 0 }" :aria-current="{ 'true': index === 0 }"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item" v-for="(train, index) in trainers" :key="train.id" :class="{ 'active': index === 0 }">
        <img :src="getPic(train.image)" class="" alt="...">
        <div class="carousel-caption d-none d-md-block">
          <h5>{{ train.name }}</h5>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev carbuttL" type="button" data-bs-target="#trainerCarousel"  data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next carbuttR" type="button" data-bs-target="#trainerCarousel"  data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "TrainerCarousel",
  props:{
    trainers: Array
  },
  methods: {
    getPic(name) {
      var images = require.context('../img/trainer/', false)
      return images('./' + name)
    },
  }
}
</script>

<style scoped>
.carousel-item {
  align-content: center;
}
img{
  max-height: 75vh;
}
.carbuttL{
  margin: auto;
  max-height: 5vh;
  background: linear-gradient(
      270deg
      ,#3d57a5,#e08225);
}

.carbuttR{
  margin: auto;
  max-height: 5vh;
  background: linear-gradient(
      270deg
      ,#e08225,#3d57a5);
}
</style>