<template>
  <section id="signup" class="cta">
    <div class="cta-content">
      <div class="container-flex">
        <div class="row align-items-center min-vh-75">
          <div class="col-md-4">
            <h2>Stop waiting.<br>Start lifting.</h2>
            <a href="http://clients.mindbodyonline.com/classic/ws?studioid=185613&stype=" target="_blank" class="btn btn-outline btn-xl page-scroll">Sign Up Today</a>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </section>
</template>

<script>
export default {
  name: "signup"
}
</script>

<style scoped>
.cta{
  position: relative;
  width: 100%;
  min-height: 75vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background: url("../img/bg/bg-cta.jpg"), #3d57a5;
  /* fallback for old browsers */
  background: url("../img/bg/bg-cta.jpg"), -webkit-linear-gradient(to left, #3d57a5, #e08225);
  /* Chrome 10-25, Safari 5.1-6 */
  background: url("../img/bg/bg-cta.jpg"), linear-gradient(to left, #3d57a5, #e08225);
  background-attachment: fixed;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  font-family: "Catamaran","Helvetica","Arial","sans-serif";
  -webkit-font-smoothing: antialiased;
}

.min-vh-75{
  min-height: 75vh !important;
}

.cta-content{
  background: RGBA(0,0,0,0.3);
  padding: 0 7vw;
  width: 100%;
  min-height: 75vh;
  color: white;
}
h2{
  font-size: 7vh;
  font-family: "Catamaran","Helvetica","Arial","sans-serif";
  -webkit-font-smoothing: antialiased;
  font-weight: 200;
  letter-spacing: 1px;
}

a{
  background-color: #3d57a5;
  font-family: "Lato","Helvetica","Arial","sans-serif";
  text-transform: uppercase;
  letter-spacing: 0.2vw;
  font-size: 1.5vh;
  border-radius: 25px;
  padding: 2vh 2vw;
  color: white;
}
a:hover, a:focus{
  background-color: #e08225;
  color: white;
}

</style>