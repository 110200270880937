<template>
  <nav class="navbar fixed-top navbar-expand-lg">
    <div class="container-fluid">
      <a class="offset-lg-1 navbar-brand" href="#top"><img src="../img/FitnessUnboundlogo_st.png" alt="Fitness Unbound"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#trainers">Trainers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#facilities">Facilities</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#signup">Sign Up</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="loginClick">{{ !loggedIn ? 'Login with Google' : 'Logout' }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "menuBar",
  props:{
    loggedIn: Boolean
  },
  methods:{
    loginClick(){
      // console.log('Test')
      this.$emit('loginClick', 'fromMenu')
    }
  },
}
</script>

<style scoped>

.navbar{
  font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
  letter-spacing: 2px;
  color: black;
  background-color: hsla(0,0%,78.4%,.95);
  padding-left: 1vw;
  height: 69px;
  font-size: 11px;
  -webkit-font-smoothing: antialiased;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-nav {
  text-transform: uppercase;
  margin-right: 5vw;
}
@media (max-width: 991px) {
  .navbar-nav {
    background-color: hsla(0,0%,68.4%,.95);
    margin-right: 0;
  }
}

.container-fluid{
  padding: 0px;
}
  .navbar-brand{
    font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
    letter-spacing: 1px;
    color: black;
    font-weight: 200;
    font-size: 18px;
    line-height: 20px;
  }
a{
  color: black;
}
a:hover, a:focus{
  color: #3d57a5;
}
</style>
