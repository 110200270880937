<template>
  <div class="header" id="top">
    <div class="container-flex">
      <div class="row align-items-center  min-vh-100">
        <div class="col-sm-6 offset-sm-1">
          <h2>
            Fitness Unbound is a 24 hours fitness facility that provides a safe, clean, convenient and professional atmosphere for you work out in.
          </h2>
          <a href="http://clients.mindbodyonline.com/classic/ws?studioid=185613&stype=" target="_blank" class="btn btn-outline btn-xl page-scroll">Sign Up Today!</a>
        </div>
        <div class="col-sm-4">
            <img src="../img/deco/gymEquipment.jpg" class="img-responsive" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "landingPage"
}
</script>

<style scoped>
.header {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url("../img/bg/bg-pattern3.png"), #3d57a5;
  /* fallback for old browsers */
  background: url("../img/bg/bg-pattern3.png"), -webkit-linear-gradient(to left, #3d57a5, #e08225);
  /* Chrome 10-25, Safari 5.1-6 */
  background: url("../img/bg/bg-pattern3.png"), linear-gradient(to left, #3d57a5, #e08225);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
  font-family: "Catamaran","Helvetica","Arial","sans-serif";

  -webkit-font-smoothing: antialiased;
}
h2{
  font-size: 3vh;
}
.min-vh-100{
  min-height: 100vh !important;
}

.img-responsive{
  width: 100%;
  background: url("../img/deco/imgBorder.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  padding: 0.5vw;
  border-radius: 1vh;
}

a{
  background-color: #3d57a5;
  font-family: "Lato","Helvetica","Arial","sans-serif";
  text-transform: uppercase;
  letter-spacing: 0.2vw;
  font-size: 1.5vh;
  border-radius: 25px;
  padding: 2vh 2vw;
  color: white;
}
a:hover, a:focus{
  background-color: #e08225;
  color: white;
}

</style>